import React, { useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';

const EditNavbarModal = ({ isOpen, onClose, setNavbarData }) => {
  const apiUrl = import.meta.env.VITE_API_URL;
  const [newNavbarData, setNewNavbarData] = useState({
    logo: '', 
    logo2:"",
    bgColor: '',
    textColor: '',
    buttonBgColor: '',
    buttonTextColor: '',
   
  });

  useEffect(() => {
    if (isOpen) {
      fetch(`${apiUrl}/layout/navbar/`)
        .then((response) => response.json())
        .then((data) => setNewNavbarData(data))
        .catch((error) => console.error('Error fetching navbar data:', error));
    }
  }, [isOpen, apiUrl]);

  const handleSave = async () => {
    try {
      const updatedData = { ...newNavbarData };

      const response = await fetch(`${apiUrl}/layout/navbar/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        const updatedData = await response.json();
        setNavbarData(updatedData); 
        onClose(); 
      } else {
        console.error('Failed to update navbar data');
      }
    } catch (error) {
      console.error('Error updating navbar data:', error);
    }
  };

  const handleLinkChange = (index, field, value) => {
    const updatedLinks = [...newNavbarData.navbarLinks];
    updatedLinks[index][field] = value;
    setNewNavbarData({ ...newNavbarData, navbarLinks: updatedLinks });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg w-full max-w-lg shadow-xl max-h-[80vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-6 text-center">Edit Navbar</h2>

        {/* Logo URL */}
        <div className="mb-6">
          <label className="block text-sm">Enter Navbar Logo 1 URL</label>
          <input
            type="text"
            value={newNavbarData.logo}
            onChange={(e) => setNewNavbarData({ ...newNavbarData, logo: e.target.value })}
            placeholder="Enter Image URL"
            className="border p-2 w-full mt-2 text-sm"
          />
          {newNavbarData.logo && (
            <div className="mt-4 flex justify-center">
              <img
                src={newNavbarData.logo}
                alt="Navbar Logo"
                className="w-32 h-32 object-contain"
              />
            </div>
          )}
        </div>

        <div className="mb-6">
          <label className="block text-sm">Enter Navbar Logo 2 URL</label>
          <input
            type="text"
            value={newNavbarData.logo2}
            onChange={(e) => setNewNavbarData({ ...newNavbarData, logo2: e.target.value })}
            placeholder="Enter Image URL"
            className="border p-2 w-full mt-2 text-sm"
          />
          {newNavbarData.logo2 && (
            <div className="mt-4 flex justify-center">
              <img
                src={newNavbarData.logo2}
                alt="Navbar Logo"
                className="w-32 h-32 object-contain"
              />
            </div>
          )}
        </div>

        {/* Color Pickers */}
        <div className="mb-6">
          <label className="block text-sm">Background Color</label>
          <div className="hex flex items-center">
            <HexColorPicker
              color={newNavbarData.bgColor}
              onChange={(color) => setNewNavbarData({ ...newNavbarData, bgColor: color })}
            />
            <input
              type="text"
              value={newNavbarData.bgColor}
              onChange={(e) => setNewNavbarData({ ...newNavbarData, bgColor: e.target.value })}
              className="border p-2 w-20 ml-4 text-sm"
            />
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm">Text Color</label>
          <div className="hex flex items-center">
            <HexColorPicker
              color={newNavbarData.textColor}
              onChange={(color) => setNewNavbarData({ ...newNavbarData, textColor: color })}
            />
            <input
              type="text"
              value={newNavbarData.textColor}
              onChange={(e) => setNewNavbarData({ ...newNavbarData, textColor: e.target.value })}
              className="border p-2 w-20 ml-4 text-sm"
            />
          </div>
        </div>

        {/* Navbar Links Management */}
        

        {/* Save and Close */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-white rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNavbarModal;
